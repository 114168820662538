<template>
  <div class="completePage">
    <div class="completePage-header">
      <div>
        <i
          class="iconfont icon-zuo1"
          style="margin-right:20px;cursor: pointer; color:#ccc;font-size:18px"
          @click="goBack"
        ></i>
        <span>分享设置</span>
      </div>
      <div>
        <el-button type="primary" @click="completePromotionPage">
          完成并提交 <i class="el-icon-right"></i>
        </el-button>
      </div>
    </div>
    <div class="completePage-container">
      <div class="left-part">
        <div>
          <h3>分享设置</h3>
          <p>设置推广页的分享样式</p>
        </div>
        <div>
          <p>
            <span>分享标题</span>
            <el-input
              v-model="sTitle"
              placeholder="建议与详情页面主题相符"
              maxlength="14"
              show-word-limit
            ></el-input>
          </p>
          <p>
            <span>分享描述</span>
            <el-input
              v-model="sDescription"
              placeholder="对标题的简要解读"
              maxlength="20"
              show-word-limit
            ></el-input>
          </p>
        </div>
      </div>
      <div class="right-part">
        <h3>分享样式预览</h3>
        <div class="share-friend-circle">
          <p>分享到朋友圈</p>
          <el-card>
            <div class="content">
              <img
                class="img-one"
                src="http://wx.qlogo.cn/mmhead/Q3auHgzwzM4mTGQic9geC7158gERWh2gMxfbfDy0eDHDQEyCQ11ibicYg/0"
                alt=""
              />
              <div>
                <p>公众号名称</p>
                <div class="info">
                  <img
                    class="img-two"
                    src="http://wx.qlogo.cn/mmhead/Q3auHgzwzM4mTGQic9geC7158gERWh2gMxfbfDy0eDHDQEyCQ11ibicYg/0"
                    alt=""
                  />
                  <div>{{ sTitle | checkTitleIsNull }}</div>
                </div>
                <p class="publish-time">
                  <span>2 分钟前</span>
                </p>
              </div>
            </div>
          </el-card>
        </div>
        <div class="share-session">
          <p>分享到会话</p>
          <el-card>
            <div class="content">
              <div>
                <p>
                  {{ sTitle | checkTitleIsNull }}
                </p>
                <div>
                  <div>
                    {{ sDescription | checkTitleIsNull }}
                  </div>
                  <img
                    class="img-one"
                    src="http://wx.qlogo.cn/mmhead/Q3auHgzwzM4mTGQic9geC7158gERWh2gMxfbfDy0eDHDQEyCQ11ibicYg/0"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <img
                  class="img-two"
                  src="http://wx.qlogo.cn/mmhead/Q3auHgzwzM4mTGQic9geC7158gERWh2gMxfbfDy0eDHDQEyCQ11ibicYg/0"
                  alt=""
                />
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { addWeChatPage, updateWeChatPage } from "@/api/novelPut";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      sTitle: "",
      sDescription: ""
    };
  },
  filters: {
    checkTitleIsNull(value) {
      return value || "分享到朋友圈时候文案预览，文字过多时换行显示";
    }
  },
  computed: {
    ...mapState([
      "moduleSelectedList",
      "canvasName",
      "editAreaStyle",
      "shareTitle",
      "shareDescription",
      "isPageUpdate",
      "pageId"
    ])
  },
  mounted() {
    this.sTitle = this.shareTitle || "";
    this.sDescription = this.shareDescription || "";
    this.initStyle();
  },
  methods: {
    ...mapMutations(["resetAllModule", "setIsPageUpdate"]),
    initStyle() {
      // 修改ui库的默认样式
      const elCard = [...document.querySelectorAll(".el-card")];
      elCard.forEach((item, index) => {
        item.classList.remove("is-always-shadow");
        item.querySelector(".el-card__body").style = "padding:16px";
        if (index === 1) {
          item.querySelector(
            ".el-card__body"
          ).style = `padding:16px; background:#EBEBEB;
         box-shadow: inset 0 0 0 1px #d3d4d6;
         `;
        }
      });
    },
    completePromotionPage() {
      if (!this.sTitle) {
        this.$message({
          showClose: true,
          message: "分享标题不能为空!",
          type: "error"
        });
        return;
      }
      if (!this.sDescription) {
        this.$message({
          showClose: true,
          message: "分享描述不能为空!",
          type: "error"
        });
        return;
      }
      let canvasBtnType = "8"; // 默认不开启 跟一键关注相关联
      const items = [];
      let obj = {};
      let bgColor = "";
      let isHasButton = this.moduleSelectedList.find(
        item => item.name === "tipsButton"
      );
      if (!isHasButton) {
        this.$message({
          showClose: true,
          message: "请在推广页面选择至少一个按钮模块!",
          type: "error"
        });
        return;
      }
      // 提取button组件里面的oneButtonAttention
      let oneButtonAttentionList = this.moduleSelectedList
        .filter(
          item =>
            typeof item.oneButtonAttention === "boolean" &&
            item.name === "tipsButton"
        )
        .map(item => item.oneButtonAttention);
      let isMixed =
        oneButtonAttentionList.some(item => item) &&
        oneButtonAttentionList.some(item => !item);
      // ! 如果是一键关注既有开启又有关闭
      if (isMixed) {
        canvasBtnType = "131080";
      }
      // ! 如果一键关注全都是开启状态
      if (oneButtonAttentionList.every(item => item)) {
        canvasBtnType = "131072";
      }
      // ! 全都是关闭状态
      if (oneButtonAttentionList.every(item => !item)) {
        canvasBtnType = "8";
      }

      this.moduleSelectedList.forEach((item, index) => {
        const moduleInfo = this.getModuleInfo(item);
        obj = {
          ...moduleInfo,
          sort: item.tagNumber,
          canvasBtnType,
          subType: item.oneButtonAttention ? "17" : "1"
        };
        items.push(obj);
      });
      const reg = /[0-9]\d+/g;
      const isRgb = /rgb/g;
      if (isRgb.test(this.editAreaStyle.backgroundColor)) {
        const value = this.editAreaStyle.backgroundColor;
        const getArr = value.match(reg);
        bgColor =
          "#" + ((getArr[0] << 16) | (getArr[1] << 8) | getArr[2]).toString(16);
      } else {
        bgColor = this.editAreaStyle.backgroundColor;
      }
      const params = {
        canvasName: this.canvasName,
        shareDesc: this.sDescription,
        shareTitle: this.sTitle,
        backgroundColor: bgColor,
        items
      };
      if (!this.isPageUpdate) {
        addWeChatPage(params).then(res => {
          this.$message({
            showClose: true,
            message: "添加成功!",
            type: "success"
          });
          window.localStorage.removeItem("bgColor");
          this.resetAllModule();
          this.$router.push({
            name: "DeliveryMaterialManage"
          });
        });
      } else {
        updateWeChatPage(this.pageId, params).then(res => {
          this.$message({
            showClose: true,
            message: "编辑成功!",
            type: "success"
          });
          window.localStorage.removeItem("bgColor");
          this.resetAllModule();
          this.$router.push({
            name: "DeliveryMaterialManage"
          });
        });
      }
      this.setIsPageUpdate(false);
    },
    getModuleInfo(item) {
      switch (item.name) {
        case "tipsImage":
          return {
            widgetType: "image",
            widgetTypeV2: "img",
            type: "41",
            name: "图片",
            paddingTop: item.marginTopValue * 2,
            paddingBottom: item.marginBottomValue * 2,
            paddingLeft: 0,
            paddingRight: 0,
            content: item.imgUrl
          };
        case "tipsText":
          return {
            widgetType: "text",
            widgetTypeV2: "text",
            type: "1",
            name: "文本",
            paddingTop: item.marginTopValue * 2,
            paddingBottom: item.marginBottomValue * 2,
            paddingLeft: 48,
            paddingRight: 48,
            fontSize: item.fontSizeStyle * 2,
            fontColor: item.fontColor,
            textAlignment:
              item.textAlignStyle === "left"
                ? 0
                : item.textAlignStyle === "center"
                ? 1
                : 2,
            showType: item.fontWeightStyle === "normal" ? 0 : 1,
            content: item.promotedText
          };

        case "tipsButton":
          return {
            widgetType: "button",
            widgetTypeV2: "gh",
            type: "21",
            name: "关注公众号",
            paddingTop: item.marginTopValue * 2,
            paddingBottom: item.marginBottomValue * 2,
            paddingLeft: 185,
            paddingRight: 185,
            fontColor: item.buttonColor,
            fontSize: 30,
            showType: item.fontWeightStyle === "normal" ? 0 : 1,
            btnTitle: item.buttonText,
            btnHeight: 80,
            btnBgColorTheme: item.buttonBgColor,
            btnBorderColorTheme: item.buttonBorderColor,
            content: item.buttonText,
            cornerRadius: 4
          };
      }
    },
    goBack() {
      this.$router.push({
        name: "PromotionPage"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.img-one {
  width: 35.994px;
  height: 35.994px;
}
.img-two {
  width: 35px;
  height: 35px;
}
.completePage {
  margin: 10px;
  .completePage-header {
    padding: 0 50px;
    height: 52px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .completePage-container {
    width: 1163px;
    height: 438px;
    // margin: 0 auto;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .left-part {
      background: #fff;
      width: 746px;
      height: 313px;
      border-radius: 4px;
      padding: 16px 24px;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.06),
        0 1px 2px 0 rgba(37, 39, 46, 0.12), 0 0 0 1px #e8e8e8;
      > div:nth-of-type(1) {
        h3 {
          font-size: 16px;
        }
        p {
          margin-top: 12px;
          font-size: 12px;
          color: #a3a3a3;
        }
      }
      > div:nth-of-type(2) {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 194px;
        text-align: center;
        //  height:
        > p {
          width: 100%;
          .el-input {
            width: 350px;
          }
          > span {
            margin-right: 40px;
          }
          &:nth-of-type(1) {
            margin-bottom: 50px;
          }
        }
      }
    }
    .right-part {
      background: #fff;
      margin-left: 10px;
      border-radius: 4px;
      width: 360px;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.06),
        0 1px 2px 0 rgba(37, 39, 46, 0.12), 0 0 0 1px #e8e8e8;
      padding: 16px 24px 42px;
      h3 {
        font-size: 15px;
      }
      .share-friend-circle {
        margin-top: 32px;
        > p {
          color: #65676e;
          font-size: 13px;
          margin-bottom: 8px;
        }
        .content {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-left: 8px;
            > p {
              font-size: 12.5px;
              color: #5e7299;
              margin-bottom: 4px;
            }
            .info {
              display: flex;
              justify-content: center;
              align-items: flex-start;
              background: #f3f3f5;
              box-sizing: border-box;
              padding: 4px;
              > div {
                font-size: 12px;
                // padding-right: 20px;
                width: 188px;
                margin-left: 4px;
                line-height: 16px;
              }
            }
            .publish-time {
              color: #787878;
              margin-top: 12px;
            }
          }
        }
      }
      .share-session {
        > p {
          margin: 16px 0;
          color: #65676e;
          font-size: 13px;
        }
        .content {
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          > div:nth-of-type(1) {
            background: #fff;
            box-sizing: border-box;
            padding: 10px;
            width: 195px;
            border-radius: 4px;
            > p:nth-of-type(1) {
              font-size: 13px;
            }
            > div {
              display: flex;
              margin-top: 8px;
              justify-content: flex-start;
              align-items: flex-start;
              font-size: 12px;
              line-height: 16px;
              color: #888;
              > div {
                width: 128px;
                word-break: break-all;
              }
            }
          }
        }
      }
    }
  }
}
</style>
